// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Print
\* ------------------------------------------------------------------------ */

// Vendor

@import "../../vendor/_gutenberg";

// Custom

a[href^="//"] {
    &:after {
        content: " (" attr(href) ")";
        font-size: 80%;
    }
}
